// slidesData.js
export const slidesData = [
    {
        title: "Personal Finance and Money on Autopilot",
        content: [
            "77% of Americans report feeling financially stressed",
            "Gap between consumer expectations and product capabilities",
            "Consumers want someone to fix their financial situation",
            "AI enables the vision of 'self-driving money'"
        ],
        image: "/api/placeholder/400/300"  // Placeholder for an image showing stressed people or a futuristic AI concept
    },
    {
        title: "How Personal Finance can be improved",
        content: [
            "AI-driven automation for saving, spending, and investing",
            "Comprehensive retirement planning",
            "Automated debt management",
            "AI-assisted tax preparation"
        ],
        image: "/api/placeholder/400/300"  // Placeholder for an image showing various financial activities
    },
    {
        title: "Solution based on AI Personal Finance Agent",
        content: [
            "AI acts as a 'refi robot' for financial tasks",
            "Can log into accounts, find best options, and execute processes",
            "Fills out applications, cancels accounts, and originates new ones",
            "Significant improvement over traditional data aggregators"
        ],
        image: "/api/placeholder/400/300"  // Placeholder for an image of a robot or AI assistant
    },
    {
        title: "Current Market with Personal Finance Applications",
        content: [
            "Budgeting and Spending Apps (e.g., Mint, YNAB)",
            "Robo-Advisors (e.g., Betterment, Wealthfront)",
            "Retirement Planning Tools",
            "Debt Management Apps",
            "Tax Preparation Software"
        ],
        image: "/api/placeholder/400/300"  // Placeholder for an image showing logos of various finance apps
    },
    {
        title: "Comparison of functionalities with existing Applications",
        content: [
            "More comprehensive than basic budgeting tools",
            "Advanced AI-driven investing compared to simple robo-advisors",
            "Integrated approach vs. specialized tools",
            "Combines features of multiple apps into one platform"
        ],
        image: "/api/placeholder/400/300"  // Placeholder for a comparison chart or infographic
    },
    {
        title: "Benefits of Finance Autopilot",
        content: [
            "Comprehensive financial management in a single platform",
            "Advanced AI-driven automation and decision-making",
            "Proactive financial planning and optimization",
            "Personalized investment strategies and risk management",
            "Continuous learning and improvement through AI"
        ],
        image: "/api/placeholder/400/300"  // Placeholder for an image showing benefits or happy users
    },
    {
        title: "Features of Finance Autopilot",
        content: [
            "AI-Driven Spending and Saving Analysis",
            "Automated Budgeting and Goal Setting",
            "Smart Investment Management",
            "Debt Optimization Strategies",
            "Retirement Planning and Forecasting",
            "Tax Preparation Assistance",
            "AI Financial Assistant for personalized advice"
        ],
        image: "/api/placeholder/400/300"  // Placeholder for an image showing app features or interface
    },
    {
        title: "Project Timeline and Budget",
        content: [
            "Start Date: March 1, 2025",
            "End Date: March 1, 2027",
            "Duration: 24 months",
            "Team: 2 Developers, 1 Designer, 2 Data Scientists, 1 Financial Expert, 1 QA Specialist",
            "Total Budget: $900,000"
        ],
        image: "/api/placeholder/400/300"  // Placeholder for a project timeline or budget chart
    },
    {
        title: "Forecast Revenue Growth",
        content: [
            "Year 1 (2027): 5,000 users",
            "Year 5 (2031): 100,000 users",
            "Year 10 (2036): 2,000,000 users",
            "Revenue Year 1: $220,000",
            "Revenue Year 5: $6.4 million",
            "Revenue Year 10: $207 million"
        ],
        image: "/api/placeholder/400/300"  // Placeholder for a revenue growth chart
    },
    {
        title: "Economic Impact and Investment Opportunities",
        content: [
            "Projected 21x return on investment after 7 years",
            "Break-even expected in Year 4 (2030)",
            "70-95% of revenues from exports",
            "Targeting global markets, especially emerging economies",
            "Opportunity to disrupt the $4.2 billion personal finance software market"
        ],
        image: "/api/placeholder/400/300"  // Placeholder for an image showing global impact or investment concept
    }
];
