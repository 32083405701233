import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ArrowRight, ChevronDown, Users, DollarSign, TrendingUp, Globe } from 'lucide-react';
import { slidesData } from './slidesData';

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

const App = () => {
  const [activeSection, setActiveSection] = useState('overview');
  const [activeSlide, setActiveSlide] = useState(0);

  const financialData = [
    { year: '2027', users: 5000, revenue: 4.95, profit: 1.49 },
    { year: '2028', users: 10000, revenue: 10.89, profit: 3.27 },
    { year: '2029', users: 20000, revenue: 23.96, profit: 7.19 },
    { year: '2030', users: 50000, revenue: 65.88, profit: 19.77 },
    { year: '2031', users: 100000, revenue: 144.95, profit: 43.48 },
  ];

  return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 text-gray-800">
        <header className="bg-white shadow-md">
          <nav className="container mx-auto px-6 py-4">
            <div className="flex items-center justify-between">
              <div className="text-2xl font-bold text-blue-600">AI Autopilot Finance</div>
              <div className="space-x-4">
                {['Overview', 'Features', 'Timeline', 'Financials', 'Partnership', 'Details'].map((item) => (
                    <button
                        key={item}
                        className={`px-3 py-2 rounded-md text-sm font-medium ${
                            activeSection === item.toLowerCase() ? 'bg-blue-500 text-white' : 'text-gray-600 hover:bg-blue-100'
                        }`}
                        onClick={() => setActiveSection(item.toLowerCase())}
                    >
                      {item}
                    </button>
                ))}
              </div>
            </div>
          </nav>
        </header>

        <main className="container mx-auto px-6 py-8">
          <motion.section
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ duration: 0.5 }}
              className={`mb-12 ${activeSection === 'overview' ? '' : 'hidden'}`}
          >
            <h1 className="text-4xl font-bold mb-6 text-blue-800">Revolutionizing Personal Finance Management</h1>
            <p className="text-xl mb-6">
              AI Autopilot Finance App is an innovative solution that leverages advanced artificial intelligence to provide users with comprehensive financial guidance and automation.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <InfoCard icon={<Users size={24} />} title="Target Users" value="100M+" />
              <InfoCard icon={<DollarSign size={24} />} title="Market Size" value="$4.2B by 2032" />
              <InfoCard icon={<TrendingUp size={24} />} title="CAGR" value="12.2%" />
              <InfoCard icon={<Globe size={24} />} title="Global Reach" value="70-95% Exports" />
            </div>
          </motion.section>

          <motion.section
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ duration: 0.5 }}
              className={`mb-12 ${activeSection === 'features' ? '' : 'hidden'}`}
          >
            <h2 className="text-3xl font-bold mb-6 text-blue-800">Key Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <FeatureCard
                  title="AI-Driven Analysis"
                  description="Advanced algorithms analyze spending patterns, income, and market trends for personalized insights."
              />
              <FeatureCard
                  title="Automated Budgeting"
                  description="AI-powered budgeting system adapts to user's financial habits and goals."
              />
              <FeatureCard
                  title="Smart Investments"
                  description="Personalized investment strategies with automated portfolio rebalancing."
              />
              <FeatureCard
                  title="Debt Optimization"
                  description="AI-driven strategies for efficient debt repayment and consolidation."
              />
              <FeatureCard
                  title="Retirement Planning"
                  description="Comprehensive tools with dynamic adjustments based on life changes."
              />
              <FeatureCard
                  title="AI Financial Assistant"
                  description="Interactive AI advisor providing personalized guidance and answering queries."
              />
            </div>
          </motion.section>

          <motion.section
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ duration: 0.5 }}
              className={`mb-12 ${activeSection === 'timeline' ? '' : 'hidden'}`}
          >
            <h2 className="text-3xl font-bold mb-6 text-blue-800">Project Timeline</h2>
            <div className="space-y-6">
              <TimelineItem
                  phase="Phase 1"
                  date="March 2025 - March 2026"
                  items={[
                    "Project planning and analysis",
                    "Development of core architecture",
                    "Implementation of basic financial functions",
                    "Integration of fundamental AI models"
                  ]}
              />
              <TimelineItem
                  phase="Phase 2"
                  date="January 2026 - September 2026"
                  items={[
                    "Advanced AI function development",
                    "Integration of comprehensive financial data",
                    "UI/UX enhancements",
                    "Security audits and optimizations"
                  ]}
              />
              <TimelineItem
                  phase="Phase 3"
                  date="July 2026 - March 2027"
                  items={[
                    "Implementation of advanced financial tools",
                    "Development of AI planning assistant",
                    "Performance optimization",
                    "Final testing and market preparation"
                  ]}
              />
            </div>
          </motion.section>

          <motion.section
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ duration: 0.5 }}
              className={`mb-12 ${activeSection === 'financials' ? '' : 'hidden'}`}
          >
            <h2 className="text-3xl font-bold mb-6 text-blue-800">Financial Projections</h2>
            <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={financialData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                  <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                  <Tooltip />
                  <Bar yAxisId="left" dataKey="revenue" fill="#8884d8" name="Revenue (M CZK)" />
                  <Bar yAxisId="right" dataKey="profit" fill="#82ca9d" name="Profit (M CZK)" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-2">ROI</h3>
                <p className="text-3xl font-bold text-green-600">21x</p>
                <p className="text-sm text-gray-600">Projected return after 7 years</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-2">Break-even</h3>
                <p className="text-3xl font-bold text-blue-600">Year 4</p>
                <p className="text-sm text-gray-600">Expected in 2030</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-2">Export Potential</h3>
                <p className="text-3xl font-bold text-purple-600">70-95%</p>
                <p className="text-sm text-gray-600">Of revenues from international markets</p>
              </div>
            </div>
          </motion.section>

          <motion.section
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ duration: 0.5 }}
              className={`mb-12 ${activeSection === 'partnership' ? '' : 'hidden'}`}
          >
            <h2 className="text-3xl font-bold mb-6 text-blue-800">Partnership Benefits</h2>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <ArrowRight className="mr-2 text-green-500 flex-shrink-0" />
                  <span>Access to cutting-edge AI technology in the rapidly growing fintech sector</span>
                </li>
                <li className="flex items-start">
                  <ArrowRight className="mr-2 text-green-500 flex-shrink-0" />
                  <span>Opportunity to enter global markets, with a focus on emerging economies</span>
                </li>
                <li className="flex items-start">
                  <ArrowRight className="mr-2 text-green-500 flex-shrink-0" />
                  <span>Collaboration with a skilled team of developers, data scientists, and financial experts</span>
                </li>
                <li className="flex items-start">
                  <ArrowRight className="mr-2 text-green-500 flex-shrink-0" />
                  <span>Potential for high returns on investment, with projected 21x ROI in 7 years</span>
                </li>
                <li className="flex items-start">
                  <ArrowRight className="mr-2 text-green-500 flex-shrink-0" />
                  <span>Participation in a project addressing a significant market need (77% of people report financial stress)</span>
                </li>
                <li className="flex items-start">
                  <ArrowRight className="mr-2 text-green-500 flex-shrink-0" />
                  <span>Flexible partnership models, including white-label solutions and revenue-sharing opportunities</span>
                </li>
              </ul>
            </div>
          </motion.section>

          <motion.section
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ duration: 0.5 }}
              className={`mb-12 ${activeSection === 'details' ? '' : 'hidden'}`}
          >
            <h2 className="text-3xl font-bold mb-6 text-blue-800">Detailed Project Overview</h2>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <div className="flex justify-between items-center mb-4">
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    onClick={() => setActiveSlide(Math.max(0, activeSlide - 1))}
                    disabled={activeSlide === 0}
                >
                  Previous
                </button>
                <span className="text-lg font-semibold">{activeSlide + 1} / {slidesData.length}</span>
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    onClick={() => setActiveSlide(Math.min(slidesData.length - 1, activeSlide + 1))}
                    disabled={activeSlide === slidesData.length - 1}
                >
                  Next
                </button>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 pr-4">
                  <h3 className="text-2xl font-bold mb-4 text-blue-700">{slidesData[activeSlide].title}</h3>
                  <ul className="space-y-2">
                    {slidesData[activeSlide].content.map((item, index) => (
                        <li key={index} className="flex items-start">
                          <ArrowRight className="mr-2 text-blue-500 flex-shrink-0 mt-1" />
                          <span>{item}</span>
                        </li>
                    ))}
                  </ul>
                </div>
                <div className="md:w-1/2 mt-4 md:mt-0">
                  <img src={slidesData[activeSlide].image} alt={slidesData[activeSlide].title} className="w-full h-auto rounded-lg shadow-md" />
                </div>
              </div>
            </div>
          </motion.section>

          <motion.section
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ duration: 0.5 }}
              className="bg-blue-600 text-white p-8 rounded-lg shadow-lg"
          >
            <h2 className="text-3xl font-bold mb-4">Interested in Partnering?</h2>
            <p className="text-xl mb-6">
              We're excited to discuss how we can work together to revolutionize personal finance management. Contact us to learn more about partnership opportunities with the AI Autopilot Finance App project.
            </p>
            <a
                href="mailto:contact@cexbit.com"
                className="inline-block bg-white text-blue-600 py-2 px-6 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300"
            >
              Contact Us
            </a>
          </motion.section>
        </main>

        <footer className="bg-gray-800 text-white py-6 mt-12">
          <div className="container mx-auto px-6 text-center">
            <p>&copy; 2024 CEXBit S.R.O. All rights reserved.</p>
          </div>
        </footer>
      </div>
  );
};

const InfoCard = ({ icon, title, value }) => (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <div className="flex items-center mb-4">
        <div className="bg-blue-100 p-3 rounded-full mr-4">{icon}</div>
        <h3 className="text-xl font-semibold">{title}</h3>
      </div>
      <p className="text-3xl font-bold text-blue-600">{value}</p>
    </div>
);

const FeatureCard = ({ title, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
      <h3 className="text-xl font-semibold mb-2 text-blue-700">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
);

const TimelineItem = ({ phase, date, items }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
          <div>
            <h3 className="text-xl font-semibold text-blue-700">{phase}</h3>
            <p className="text-gray-600">{date}</p>
          </div>
          <ChevronDown className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} />
        </div>
        {isOpen && (
            <ul className="mt-4 space-y-2">
              {items.map((item, index) => (
                  <li key={index} className="flex items-start">
                    <ArrowRight className="mr-2 text-blue-500 flex-shrink-0" />
                    <span>{item}</span>
                  </li>
              ))}
            </ul>
        )}
      </div>
  );
};

export default App;
